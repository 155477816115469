<template>
  <div>
    <v-form>
      <p class="mb-5">Match the glassware setups to the separation process:</p>
      <br />
      <v-simple-table>
        <thead></thead>
        <tbody>
          <tr>
            <td>{{ rowNames[0] }}</td>
            <td v-for="image in images" :key="image.alt">
              <img :src="image.img" :alt="image.alt" style="max-height: 150px" />
            </td>
          </tr>
          <tr>
            <td>{{ rowNames[1] }}</td>
            <td v-for="(option, index) in optionsProcessShuffled" :key="option">
              <v-select
                :key="option"
                v-model="inputs.processAnswers[index]"
                class="my-1"
                :items="optionsProcessShuffled"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI_1LD_Q4S2_Q2',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        processAnswers: [],
      },
      rowNames: ['Glassware setup', 'Separation Process'],
      images: [
        {
          img: '/img/assignments/ChemUCI_1LD_Q4S2_Q2I.png',
          alt: 'image of filtration',
        },
        {
          img: '/img/assignments/ChemUCI_1LD_Q4S2_Q2II.png',
          alt: 'image of a crystallization',
        },
        {
          img: '/img/assignments/ChemUCI_1LD_Q4S2_Q2III.png',
          alt: 'image of a TLC',
        },
      ],
      optionsName: [
        {
          text: 'Filtration',
          value: 'Filtration',
        },
        {
          text: 'Thin Layer Chromatography',
          value: 'Thin Layer Chromatography',
        },
        {
          text: 'Crystallization',
          value: 'Crystallization',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsProcessShuffled() {
      return seededShuffle(this.optionsName, this.seed);
    },
  },
};
</script>
